import React from "react";
import Layout from "components/layout";
import TranslationCost from "components/homepage/translationCost/index";
import SEO from "components/seo";

export default function index() {
  const description =
    "Tomedes translation agency provides professional translation services with clear translation cost and translation price services for all documents";
  const title = "Translation Cost - Translation Rates | Tomedes";
  const keywords = "";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/translation-cost"
      />
      <TranslationCost />
    </Layout>
  );
}
